<template>
  <div>
    <div v-if="isVisible" class="kt-container mt-5">
      <b-alert show variant="warning" class="text-center align-middle">{{
        $t("INFORMATIONS.REVENUE_DATA_START")
      }}</b-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AlertRevenueDateStart",

  props: {
    isDateWarning: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    ...mapGetters(["isAdmin", "currentUser"]),

    permission() {
      if (this.isAdmin) {
        return true;
      } else if (this.currentUser.company) {
        return true;
      }
      return false;
    },

    isVisible() {
      return !this.isAdmin && this.permission && this.isDateWarning;
    }
  }
};
</script>
