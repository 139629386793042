const normalizeRevenusDataTable = periodDatas => {
  delete periodDatas["total"];
  delete periodDatas["debugQuery"];
  let results = [];

  for (var key in periodDatas) {
    results.push({
      date: key,
      est_earnings: periodDatas[key]["earnings"] || 0,
      // inventories: periodDatas[key]["inventories"] || 0,
      listens: periodDatas[key]["plays:paid"] || 0,
      organic_listens: periodDatas[key]["plays:organic"] || 0,
      impressions: periodDatas[key]["impressions"] || 0
      // conversionrate: periodDatas[key]["conversionrate"],
      // fillrate: periodDatas[key]["fillrate"],
      // est_rpm: periodDatas[key]["rpm"] || 0,
      // est_rpm_plays: periodDatas[key]["rpm:plays"] || 0
    });
  }
  return results;
};

export const tableFields = [
  {
    key: "program.name",
    label: "Name",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    isAdmin: false
  },
  {
    key: "earnings",
    label: "Est. Earnings",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item.earnings + "€";
    },
    isAdmin: false
  },
  {
    key: "plays:paid",
    label: "Paid plays",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item["plays:paid"]
      );
    },
    isAdmin: false
  },
  {
    key: "plays:organic",
    label: "Organic plays",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item["plays:organic"]
      );
    },
    isAdmin: false
  },
  {
    key: "inventories",
    label: "Inventory",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item["inventories"]
      );
    },
    isAdmin: true
  },
  {
    key: "impressions",
    label: "Native ads impressions",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(
        item["impressions"]
      );
    },
    isAdmin: false
  },
  {
    key: "conversionrate",
    label: "Conversion rate",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item["conversionrate"] + "%";
    },
    isAdmin: true
  },
  {
    key: "fillrate",
    label: "Fill rate",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item["fillrate"] + "%";
    },
    isAdmin: true
  },
  {
    key: "rpm",
    label: "Est. Impressions RPM",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item["rpm"] + "€";
    },
    isAdmin: true
  },
  {
    key: "rpm:plays",
    label: "Est. Plays RPM",
    sortable: true,
    sortDirection: "desc",
    tdClass: "text-left align-middle",
    formatter: (value, key, item) => {
      return item["rpm:plays"] + "€";
    },
    isAdmin: true
  }
];

export const tabsData = [
  {
    loading: true,
    title: "Est. Earnings",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/Euro2.svg",
    iconSVGColor: "dark",
    theme: "light",
    value: "earnings",
    selected: true,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.EARNING"
    },
    renderFigure: function(x) {
      if (x !== "-") {
        return new Intl.NumberFormat(navigator.language).format(x) + "€";
      }
      return "-€";
    },
    normalizeDataTable: function(datas) {
      return normalizeRevenusDataTable(datas);
    },
    chartDataToDisplay: [
      {
        value: "earnings:classic",
        color: "#FF961D",
        label: "AudioHub"
      },
      {
        value: "earnings:embed",
        color: "#FF4867",
        label: "Embed"
      },
      {
        value: "earnings:discovery",
        color: "#FFF01F",
        label: "Discovery"
      }
    ],
    isAdmin: false
  },
  {
    loading: true,
    title: "Native inventory",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Devices/TV1.svg",
    iconSVGColor: "success",
    cardClass: "bg-pi",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.INVENTORY"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x["inventories"]);
    },
    chartDataToDisplay: [
      {
        value: "inventories:classic",
        color: "#FF961D",
        label: "AudioHub"
      },
      {
        value: "inventories:embed",
        color: "#FF4867",
        label: "Embed"
      },
      {
        value: "inventories:discovery",
        color: "#ec7063",
        label: "Discovery"
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Native ads impressions",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Devices/TV1.svg",
    iconSVGColor: "primary",
    cardClass: "bg-pi",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.IMPRESSIONS"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x["impressions"]);
    },
    normalizeDataTable: function(datas) {
      return normalizeRevenusDataTable(datas);
    },
    chartDataToDisplay: [
      {
        value: "impressions:classic",
        color: "#FF961D",
        label: "AudioHub"
      },
      {
        value: "impressions:embed",
        color: "#FF4867",
        label: "Embed"
      },
      {
        value: "impressions:discovery",
        color: "#ec7063",
        label: "Discovery"
      }
    ],
    isAdmin: false
  },
  {
    loading: true,
    title: "Conversion Rate",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Communication/Check.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    selected: false,
    isTimeChart: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.CONVERSION_RATE"
    },
    renderFigure: function(x) {
      return (
        new Intl.NumberFormat(navigator.language).format(x["conversionrate"]) +
        "%"
      );
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        value: "conversionrate:classic",
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(datas) {
          return datas["conversionrate:classic"];
        }
      },
      {
        value: "conversionrate:embed",
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(datas) {
          return datas["conversionrate:embed"];
        }
      },
      {
        value: "conversionrate:discovery",
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#ec7063",
        renderData: function(datas) {
          return datas["conversionrate:discovery"];
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Fill Rate",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Communication/Check.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    selected: false,
    isTimeChart: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.FILL_RATE"
    },
    renderFigure: function(x) {
      return (
        new Intl.NumberFormat(navigator.language).format(x["fillrate"]) + "%"
      );
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        value: "fillrate:classic",
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(datas) {
          return datas["fillrate:classic"];
        }
      },
      {
        value: "fillrate:embed",
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(datas) {
          return datas["fillrate:embed"];
        }
      },
      {
        value: "fillrate:discovery",
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#ec7063",
        renderData: function(datas) {
          return datas["fillrate:discovery"];
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Impressions RPM",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/PlayEuro.svg",
    iconSVGColor: "dark",
    theme: "light",
    value: "rpm",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.IMPRESSION_RPM"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x) + "€";
    },
    customOptions: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              maxRotation: 0,
              autoSkipPadding: 100,
              fontColor: "#2ecc71"
            },

            stacked: true
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false,
              borderDash: [4, 8],
              color: "#2ecc71"
            },
            ticks: {
              fontColor: "#2ecc71",
              maxTicksLimit: 10,
              beginAtZero: true
            },
            stacked: false
          }
        ]
      }
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        value: "rpm:classic",
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(datas) {
          return datas["rpm:classic"];
        }
      },
      {
        value: "rpm:embed",
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(datas) {
          return datas["rpm:embed"];
        }
      },
      {
        value: "rpm:discovery",
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#ec7063",
        renderData: function(datas) {
          return datas["rpm:discovery"];
        }
      }
    ],
    isAdmin: true
  },
  {
    loading: true,
    title: "Plays RPM",
    primaryStat: "-",
    previousCompare: "-",
    iconSVG: "assets/media/icons/svg/Shopping/PlayEuro.svg",
    iconSVGColor: "dark",
    theme: "light",
    value: "rpm:plays",
    selected: false,
    tooltip: {
      descriptionKey: "NATIVE_REVENUE.TOOLTIPS.PLAY_RPM"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x) + "€";
    },
    customOptions: {
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              maxRotation: 0,
              autoSkipPadding: 100,
              fontColor: "#2ecc71"
            },

            stacked: true
          }
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false,
              borderDash: [4, 8],
              color: "#2ecc71"
            },
            ticks: {
              fontColor: "#2ecc71",
              maxTicksLimit: 10,
              beginAtZero: true
            },
            stacked: false
          }
        ]
      }
    },
    chartOptions: {
      chartType: "area",
      stacked: true
    },
    chartDataToDisplay: [
      {
        value: "rpm:plays:classic",
        color: "rgba(255,150,29,0.3)",
        label: "AudioHub",
        borderColor: "#FF961D",
        renderData: function(datas) {
          return datas["rpm:plays:classic"];
        }
      },
      {
        value: "rpm:plays:embed",
        color: "rgba(255,72,103,0.2)",
        label: "Embed",
        borderColor: "#FF4867",
        renderData: function(datas) {
          return datas["rpm:plays:embed"];
        }
      },
      {
        value: "rpm:plays:discovery",
        color: "rgba(236, 112, 99,0.2)",
        label: "Discovery",
        borderColor: "#2ecc71",
        renderData: function(datas) {
          return datas["rpm:plays:discovery"];
        }
      }
    ],
    isAdmin: true
  }
];
