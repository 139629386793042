const universalBOM = "\uFEFF";

export const download = (content, fileName) => {
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + content)
  );
  link.setAttribute("download", `${fileName}`);
  link.click();
};
