<template>
  <div>
    <v-row v-if="!permission">
      <b-col xs="12">
        <h2 class="text-left pl-5">Forbidden</h2>
      </b-col>
    </v-row>

    <FiltersTemplate
      v-if="permission"
      :inputFilters="inputFilters"
      apiSlug="revenue"
    />

    <AlertExpirePassword />
    <AlertRevenueDateStart v-bind:isDateWarning="isDateWarning" />

    <StatsPage
      v-if="permission"
      v-bind:APIslug="'revenue'"
      v-bind:tabsData="computedTabs"
      v-bind:displayChart="true"
      v-bind:displayCreate="false"
      v-bind:inputFilters="inputFilters"
      class="kt-container"
    >
      <div class="row-cards my-3" v-if="isAdmin">
        <div class="card border-0">
          <div class="card-body text-right">
            <ButtonExport
              :name="'programs revenue'"
              :headers="headersPrograms"
              :datas="exportDataPrograms"
            />

            <ButtonExport
              :name="'programs campaigns revenue'"
              :headers="headersProgramsCampaigns"
              :datas="exportDataProgramsCampaigns"
            />
          </div>
        </div>
      </div>

      <div v-if="isPaidCustomer || isAdmin" class="row row-cards">
        <Table
          v-bind:fields="computedTableFields"
          v-bind:loading="tableIsLoading"
          v-bind:items="programs"
          v-bind:rowClickable="true"
          v-bind:visibleCard="true"
        ></Table>
      </div>
    </StatsPage>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import StatsPage from "@/components/layout/StatsPage";
import { mapGetters } from "vuex";
import Table from "@/views/partials/content/cards/Table.vue";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import { inputFilters } from "@/common/config/filters/revenue";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import AlertRevenueDateStart from "@/components/AlertRevenueDateStart";

import { tableFields, tabsData } from "@/common/config/dashboard/revenue";
import ButtonExport from "../../components/button/ButtonExport.vue";
import { getRevenuesPrograms } from "@/api/dashboard/revenues";
import ApiService from "@/common/api.service";

export default {
  name: "revenue",

  data() {
    return {
      programs: [],
      tableIsLoading: true,
      inputFilters: inputFilters,
      fields: tableFields,
      tabsData: tabsData,
      isDateWarning: false
    };
  },

  computed: {
    ...mapGetters(["filters", "currentUser", "isAdmin", "isPaidCustomer"]),
    computedTabs() {
      return this.tabsData.filter(row => {
        if (row.isAdmin && !this.isAdmin) {
          return false;
        }
        return true;
      });
    },

    computedTableFields() {
      return this.fields.filter(row => {
        if (row.isAdmin && !this.isAdmin) {
          return false;
        }
        return true;
      });
    },

    permission() {
      if (this.isAdmin) {
        return true;
      } else if (this.currentUser.company) {
        return true;
      }
      return false;
    },

    headersPrograms() {
      return [
        { program_name: "program_name" },
        { program_domain: "program_domain" },
        { inventories: "inventories" },
        { "inventories:classic": "inventories : AudioHub" },
        { "inventories:embed": "inventories : Embed" },
        { "inventories:discovery": "inventories : Discovery" },
        { impressions: "impressions" },
        { "impressions:classic": "impressions : AudioHub" },
        { "impressions:embed": "impressions : Embed" },
        { "impressions:discovery": "impressions : Discovery" },
        { "plays:paid": "plays:paid" },
        { "plays:paid:classic": "plays:paid : AudioHub" },
        { "plays:paid:embed": "plays:paid : Embed" },
        { "plays:paid:discovery": "plays:paid : Discovery" },
        { "plays:organic": "plays:organic" },
        { "plays:organic:classic": "plays:organic : AudioHub" },
        { "plays:organic:embed": "plays:organic : Embed" },
        { "plays:organic:discovery": "plays:organic : Discovery" },
        { conversionrate: "impressions conversion rate" },
        { "conversionrate:classic": "impressions conversion rate : classic" },
        { "conversionrate:embed": "impressions conversion rate : embed" },
        {
          "conversionrate:discovery": "impressions conversion rate : discovery"
        },
        { fillrate: "fillrate" },
        { "fillrate:classic": "fillrate : AudioHub" },
        { "fillrate:embed": "fillrate : Embed" },
        { "fillrate:discovery": "fillrate : Discovery" },
        { earnings: "earnings" },
        { "earnings:classic": "earnings : AudioHub" },
        { "earnings:embed": "earnings : Embed" },
        { "earnings:discovery": "earnings : Discovery" },
        { rpm: "impressions rpm" },
        { "rpm:classic": "impressions rpm : AudioHub" },
        { "rpm:embed": "impressions rpm : Embed" },
        { "rpm:discovery": "impressions rpm : Discovery" },
        { "rpm:plays": "plays rpm" },
        { "rpm:plays:classic": "plays rpm : AudioHub" },
        { "rpm:plays:embed": "plays rpm : Embed" },
        { "rpm:plays:discovery": "plays rpm : Discovery" }
      ];
    },
    headersProgramsCampaigns() {
      return [
        { program_name: "program_name" },
        { program_domain: "program_domain" },
        { campaign_name: "campaign_name" },
        { campaign_id: "campaign_id" },
        { campaign_cpl: "campaign_cpl" },
        { campaign_startAt: "campaign_startAt" },
        { campaign_endAt: "campaign_endAt" },
        { campaign_program_earnings: "campaign_program_earnings" },
        { campaign_advertiser: "Advertiser" },
        { campaign_agency: "Agency" },
        { campaign_autopromo: "Autopromo ?" },
        { impressions: "impressions" },
        { "impressions:classic": "impressions : AudioHub" },
        { "impressions:embed": "impressions : Embed" },
        { "impressions:discovery": "impressions : Discovery" },
        { "plays:paid": "plays:playspaid" },
        { "plays:paid:classic": "plays:paid : AudioHub" },
        { "plays:paid:embed": "plays:paid : Embed" },
        { "plays:paid:discovery": "plays:paid : Discovery" },
        { conversionrate: "impressions conversion rate" },
        { "conversionrate:classic": "impressions conversion rate : AudioHub" },
        { "conversionrate:embed": "impressions conversion rate : Embed" },
        {
          "conversionrate:discovery": "impressions conversion rate : Discovery"
        },
        { earnings: "earnings" },
        { "earnings:classic": "earnings : AudioHub" },
        { "earnings:embed": "earnings : Embed" },
        { "earnings:discovery": "earnings : Discovery" },
        { rpm: "impressions rpm" },
        { "rpm:classic": "impressions rpm : classic" },
        { "rpm:embed": "impressions rpm : embed" },
        { "rpm:discovery": "impressions rpm : discovery" }
      ];
    },
    exportDataPrograms() {
      const exportData = [];
      for (const key in this.programs) {
        const stats = this.programs[key];
        const program = stats.program;
        exportData.push({
          program_name: program.name,
          program_domain: program.domain,
          inventories: stats["inventories"],
          "inventories:classic": stats["inventories:classic"],
          "inventories:embed": stats["inventories:embed"],
          "inventories:discovery": stats["inventories:discovery"],
          impressions: stats["impressions"],
          "impressions:classic": stats["impressions:classic"],
          "impressions:embed": stats["impressions:embed"],
          "impressions:discovery": stats["impressions:discovery"],
          "plays:paid": stats["plays:paid"],
          "plays:paid:classic": stats["plays:paid:classic"],
          "plays:paid:embed": stats["plays:paid:embed"],
          "plays:paid:discovery": stats["plays:paid:discovery"],
          "plays:organic": stats["plays:organic"],
          "plays:organic:classic": stats["plays:organic:classic"],
          "plays:organic:embed": stats["plays:organic:embed"],
          "plays:organic:discovery": stats["plays:organic:discovery"],
          conversionrate: stats["conversionrate"],
          "conversionrate:classic": stats["conversionrate:classic"],
          "conversionrate:embed": stats["conversionrate:embed"],
          "conversionrate:discovery": stats["conversionrate:discovery"],
          fillrate: stats["fillrate"],
          "fillrate:classic": stats["fillrate:classic"],
          "fillrate:embed": stats["fillrate:embed"],
          "fillrate:discovery": stats["fillrate:discovery"],
          earnings: stats["earnings"],
          "earnings:classic": stats["earnings:classic"],
          "earnings:embed": stats["earnings:embed"],
          "earnings:discovery": stats["earnings:discovery"],
          rpm: stats["rpm"],
          "rpm:classic": stats["rpm:classic"],
          "rpm:embed": stats["rpm:embed"],
          "rpm:discovery": stats["rpm:discovery"],
          "rpm:plays": stats["rpm:plays"],
          "rpm:plays:classic": stats["rpm:plays:classic"],
          "rpm:plays:embed": stats["rpm:plays:embed"],
          "rpm:plays:discovery": stats["rpm:plays:discovery"]
        });
      }
      return exportData;
    },
    exportDataProgramsCampaigns() {
      const exportData = [];
      for (const key in this.programs) {
        const stats = this.programs[key];
        const program = stats.program;
        const campaigns = stats.campaigns;
        const data = {
          program_name: program.name,
          program_domain: program.domain
        };

        for (const campaignId in campaigns) {
          const campaign = campaigns[campaignId];
          exportData.push({
            ...data,
            campaign_name: campaign.name,
            campaign_id: campaignId,
            campaign_cpl: campaign.cpl,
            campaign_startAt: campaign.startAt,
            campaign_endAt: campaign.endAt,
            campaign_program_earnings: campaign.earnings,
            campaign_advertiser: campaign.advertiser,
            campaign_agency: campaign.agency,
            campaign_autopromo: campaign.autopromo ? "yes" : "no",
            impressions: campaign["impressions"],
            "impressions:classic": campaign["impressions:classic"],
            "impressions:embed": campaign["impressions:embed"],
            "impressions:discovery": campaign["impressions:discovery"],
            "plays:paid": campaign["plays:paid"],
            "plays:paid:classic": campaign["plays:paid:classic"],
            "plays:paid:embed": campaign["plays:paid:embed"],
            "plays:paid:discovery": campaign["plays:paid:discovery"],
            conversionrate: campaign["conversionrate"],
            "conversionrate:classic": campaign["conversionrate:classic"],
            "conversionrate:embed": campaign["conversionrate:embed"],
            "conversionrate:discovery": campaign["conversionrate:discovery"],
            earnings: campaign["earnings"],
            "earnings:classic": campaign["earnings:classic"],
            "earnings:embed": campaign["earnings:embed"],
            "earnings:discovery": campaign["earnings:discovery"],
            rpm: campaign["rpm"],
            "rpm:classic": campaign["rpm:classic"],
            "rpm:embed": campaign["rpm:embed"],
            "rpm:discovery": campaign["rpm:discovery"]
          });
        }
      }
      return exportData;
    }
  },
  components: {
    StatsPage,
    Table,
    FiltersTemplate,
    AlertExpirePassword,
    AlertRevenueDateStart,
    ButtonExport
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Native revenue",
        pageCategory: "Revenue"
      }
    ]);
  },
  watch: {
    filters() {
      this.isDateWarning =
        Date.parse(this.filters.dateRange[0]) < Date.parse("2022-08-01");
      this.loadStats();
    }
  },
  methods: {
    loadStats() {
      this.tableIsLoading = true;
      this.programs = [];

      if (this.revenueProgramsCancelToken != null) {
        this.revenueProgramsCancelToken.cancel(
          ApiService.getCancelErrorMessage()
        );
      }

      this.revenueProgramsCancelToken = ApiService.getCancelToken();

      getRevenuesPrograms(
        this.axios,
        {},
        { ...this.getQueryFilters() },
        {
          cancelToken: this.revenueProgramsCancelToken.token,
          timeout: this.timeout
        }
      )
        .then(data => {
          if (Array.isArray(data)) {
            this.programs = data;
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.tableIsLoading = false;
        });
    },

    getQueryFilters() {
      const filters = { ...this.filters };
      delete filters.dateRange;

      const periode = {
        start_date: this.filters.dateRange[0].substring(0, 10),
        end_date:
          new Date(this.filters.dateRange[1]) > new Date()
            ? new Date().getTime() / 1000
            : this.filters.dateRange[1].substring(0, 10)
      };

      return {
        ...filters,
        ...periode
      };
    }
  }
};
</script>
